// React Imports
import React, { useState, useEffect }   from "react";
import MailchimpSubscribe               from "react-mailchimp-subscribe";
import InputField                       from './InputField';

// Style Imports
import "../assets/scss/index.scss";

const CustomMailchimpForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');

    useEffect(() => {
        if (status === "success") clearFields();
    }, [status])

    const clearFields = () => {
        setEmail('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,
            });
    }

    return (
        <form
            onSubmit={(e) => handleSubmit(e)}
        >
            {status != "sending" && status != "error" && status != "success" && (
                <h1>
                    a creative studio
                </h1>
            )}
            {status === "sending" && (
                <h1>
                    sending...
                </h1>
            )}
            {status === "error" && (
                <h1
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <h1>
                    thank you :)
                </h1>
            )}

            <div className="osh-field-container">
                <InputField
                    onChangeHandler={setEmail}
                    type="email"
                    value={email}
                    placeholder="enter email for updates"
                    isRequired
                />
            </div>

            <InputField
                label="submit"
                type="submit"
                formValues={[email]}
            />
        </form>
    );
};

const ContactForm = props => {

    const postUrl = `https://studio.us13.list-manage.com/subscribe/post?u=b6e6e8aafcf46407b042fba60&id=902ad9814c`;

    return (
        <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
                <CustomMailchimpForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    );
};

const App = () => (
    <section className='app'>
        <div className="container">
            <svg width="176" height="100" viewBox="0 0 176 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M122.882 39.695L91.5037 44.1375L91.4837 55.5125L122.859 51.07L122.797 85.19L80.9613 91.115L80.9812 79.74L112.359 75.2975L112.379 63.9225L81.0037 68.3675L81.066 34.245L122.902 28.3225L122.882 39.695Z" fill="white" />
                <path d="M39.2303 40.17L39.2104 51.545L28.7527 53.025L28.668 98.52L70.5036 92.595L70.6082 35.7275L39.2303 40.17ZM60.1057 59.955L60.0858 71.3275L60.0659 82.7L49.6082 84.18L39.1506 85.66L39.1705 74.2875L39.1905 62.915L39.2104 51.5425L49.668 50.0625L60.1257 48.5825L60.1057 59.955Z" fill="white" />
                <path d="M164.695 45.145L143.777 48.1075L143.819 25.36L133.359 26.84L133.255 83.71L143.715 82.2275L143.757 59.48L164.675 56.5175L164.633 79.265L175.093 77.785L175.197 20.9175L164.737 22.3975L164.695 45.145Z" fill="white" />
                <path d="M175.217 9.5425L157.808 0L11.3832 20.735L11.3633 32.1075L0.903174 33.59L0.798523 90.4575L18.208 100L18.3126 43.1325L28.7728 41.65L28.7927 30.275L175.217 9.5425Z" fill="white" />
            </svg>
            <ContactForm />
        </div>
    </section>
);

export default App;