const InputField = (props) => {

    const validateInput = (values) => {
        if (values.some((f) => f === "") || values[0].indexOf("@") === -1) {
            return true;
        } else {
            return false;
        }
    };

    if (props.type === "submit") {
        return (
            <input
                className="osh-submit"
                type="submit"
                value={props.label}
                disabled={validateInput(props.formValues)}
            />
        );
    } else {
        return (
            <label className="osh-label">
                {props.label}
                <input
                    onChange={(e) => props.onChangeHandler(e.target.value)}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    required={props.isRequired}
                    className="osh-form"
                    name={props.name}
                />
            </label>
        );
    }
};

export default InputField;
